import React from "react";
import styles from '../mna-info.module.scss';
import classnames from 'classnames/bind';
import {Field, FormikValues, useFormikContext} from "formik";
import {InputBox, NumberInputBox} from "../edit-common";
import {useInjection} from "inversify-react";
import {DialogManager} from "../../../components/dialog-manager";
import BusinessFieldPop from "./business-field-pop";
import { CustomerDetail } from "../../../model/customer/dto/customer-detail";
import { getFinancialStatus } from "../../report/report-util";
import { CompanyPfKw, EarningsEstimate } from "../../../model/customer/dto/customer-mna-brokerage-dto";

const cx = classnames.bind(styles);

const CompanyTypeForm = ({data, info, perform} : {data: CustomerDetail, info: CompanyPfKw[], perform: EarningsEstimate[]}) => {
    const dialogManager = useInjection(DialogManager);
    const {values, setFieldValue} = useFormikContext<FormikValues>();
    const today = new Date();

    const [fnDatas, accDt] = getFinancialStatus(data.summary);
    const fnData = fnDatas?.slice(fnDatas.length - 1, fnDatas.length);  // fnData[3]: 매출액, fnData[6]: 매출액증가율, fnData[7]: 영업이익률

    return (
        <div className={cx('company-type-form')}>
            <p className={cx('sub-title')}>1. 주요 제품</p>
            <InputBox name={'mainProduct'} inputWidth='100%' placeholder='입력'/>
            <p className={cx('sub-title')}>2. 사업영역</p>
            <InputBox name={'businessName'} inputWidth='100%' readOnly placeholder='입력' inputboxCn={cx('select-box')} onClick={() => {
                dialogManager.open(BusinessFieldPop, {info: info, setFieldValue: setFieldValue});
            }}/>
            <p className={cx('sub-title')}>3. 사업분야 상세 설명</p>
            <Field name={'bizCateDetail'} value={values.bizCateDetail || ''} as='textarea' placeholder='입력' className={cx('text-area')}/>
            <p className={cx('sub-title', 'gap2')}>4. {today.getFullYear()}년 매출액 및 영업이익 예상치, 향후 3년간 실적 전망</p>
            <div className={cx('flex-row-box')}>
                <div className={cx('flex-column-box')}>
                    <p className={cx('opacity-0')}>제목</p>
                    <p className={cx('no-input-box')}>매출액</p>
                    <p className={cx('no-input-box')}>매출액증가율</p>
                    <p className={cx('no-input-box')}>영업이익률</p>
                </div>
                <div className={cx('flex-column-box')}>
                    <p>{today.getFullYear() - 1}년</p>
                    {
                        fnData ?
                        <>
                            <p className={cx('no-input-box')}>{fnData[0][3] ? (fnData[0][3] / 1e5).toFixed(2) : 0}억원</p>
                            <p className={cx('no-input-box')}>{fnData[0][6] ? (fnData[0][6] * 100).toFixed(1) : 0}%</p>
                            <p className={cx('no-input-box')}>{fnData[0][7] ? (fnData[0][7] * 100).toFixed(1) : 0}%</p> 
                        </>
                        
                        : <></>
                    }
                    
                </div>
                {
                    perform.map((data, i) => {
                        return (
                            <div key={data.year} className={cx('flex-column-box')}>
                                <p>{data.year}년</p>
                                <NumberInputBox name={`perform.${i}.sales`} unit='억원' inputWidth='10.4rem'/>
                                <NumberInputBox name={`perform.${i}.salesGrowthRate`} unit='%' inputWidth='10.4rem'/>
                                <NumberInputBox name={`perform.${i}.profitRate`} unit='%' inputWidth='10.4rem'/>
                            </div>
                        )
                    })
                }
            </div>
            <p className={cx('sub-title')}>5. 해당 기업의 주요 매출처</p>
            <Field name={'mainSalesChannel'} value={values.mainSalesChannel || ''} as='textarea' placeholder='입력' className={cx('text-area')}/>
            <p className={cx('sub-title')}>6. 해당 사업 아이템의 경쟁우위 요소</p>
            <Field name={'uniqueSellingPoints'} value={values.uniqueSellingPoints || ''} as='textarea' placeholder='입력' className={cx('text-area')}/>
            <p className={cx('sub-title')}>7. 해당 기업을 인수했을 때 시너지를 낼 수 있는 기업(사업분야)</p>
            <Field name={'synergisticPartners'} value={values.synergisticPartners || ''} as='textarea' placeholder='입력' className={cx('text-area')}/>
        </div>
    );
}

export default CompanyTypeForm;