import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {EvalInfo} from "../co-report/dto/eval-info";
import {ApiResult} from "../api-result";
import {CoReportDto} from "../co-report/dto/co-report-dto";
import {InheritanceInfo} from "../co-report/dto/inheritance-info";
import {DividendInputDto} from "../co-report/dto/dividend-input-dto";
import {PrFrStat} from "./dto/financial-statement";
import {ModifyDividend, ModifySalary} from "./form/modify-form";
import {EmergencyFundPlan, PrCustomerRawData, StockTransferPlanDto} from "./dto/customer-raw-data";
import {AnnualEmployeeInfo} from "../co-report/dto/annual-employee-info";
import {MngSummaryCheckItem} from "../co-report/dto/mng-total-summary";
import {ProvPayInfo} from "../co-report/dto/prov-pay-info";
import {ModifyCustomerIssueForm} from "./form/modify-customer-issue-form";
import { CustomerMnaBrokerageDto } from "./dto/customer-mna-brokerage-dto";
import { CustomerMnaBrokerageForm } from "./form/customer-mna-brokerage-form";
import { makeSearchDataQs } from "../../util/str-util";
import { Page } from "../common/dto/page";

export type CustomerInputType = 'eval'|'familySharePlan'|'inheritance'|'salary'|'dividend'|'emergencyFundPlan'|'stockTransferPlan'|'annualEmployeeInfo'|'mngSummaryCheck'|'provPayInfo';

export type PrFrSrcType = 'HOMETAX'|'TAX_ACC'|'TAX_ACC_2'|'CRETOP_XLSX';

export interface ReportAndRawInputDto {
    report: CoReportDto;

    rawData: PrCustomerRawData;
}

@injectable()
export class CustomerInputApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;


    saveEvalInfo = async (customerId: string, data: EvalInfo) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/eval?id=' + customerId, data);
    }

    saveFamilySharePlan = async (customerId: string, data: DividendInputDto) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/familySharePlan?id=' + customerId, data);
    }

    saveSalaryInfo = async (customerId: string, data: ModifySalary) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/salary?id=' + customerId, data);
    }

    saveDividend = async (customerId: string, data: ModifyDividend) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/dividend?id=' + customerId, data);
    }

    saveInheritanceInfo = async (customerId: string, data: InheritanceInfo) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/inheritance?id=' + customerId, data);
    }

    saveEmergencyFundPlan = async (customerId: string, data: EmergencyFundPlan) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/emergencyFundPlan?id=' + customerId, data);
    }

    saveStockTransferPlan = async (customerId: string, data: StockTransferPlanDto[]) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/stockTransferPlan?id=' + customerId, data);
    }

    saveAnnualEmployeeInfo = async (customerId: string, data: AnnualEmployeeInfo[]) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/annualEmployee?id=' + customerId, data);
    }

    saveSoleAnnualEmployeeInfo = async (customerId: string, data: AnnualEmployeeInfo[]) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/sole/annualEmployee?id=' + customerId, data);
    }

    saveMngSummaryList = async (customerId: string, data: MngSummaryCheckItem[]) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/mngSummaryCheck?id=' + customerId, data);
    }

    saveProvPayInfo = async (customerId: string, data: ProvPayInfo) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>('/customer/input/provPayInfo?id=' + customerId, data);
    }

    ignoreProvPay = async (customerId: string, ignoreProvPay: boolean) => {
        return await this.apiCall.json<ApiResult<CoReportDto>>(`/customer/input/ignoreProvPay?id=${customerId}&ignoreProvPay=${ignoreProvPay}`, {});
    }

    clear = async (customerId: string, type: CustomerInputType) => {
        return await this.apiCall.fetch<ApiResult<ReportAndRawInputDto>>(`/customer/input/clear/${customerId}/${type}`);
    }

    uploadFsPdf = async (customerId: string, srcType: PrFrSrcType, workYear: number, applyYear: number, file: File[]| Blob[]) : Promise<PrFrStat> =>  {
        const form = new FormData();

        file.forEach((f) => form.append("pdfFiles", f));

        form.append("workYear", workYear.toString());
        form.append("applyYear", applyYear.toString());

        return await this.apiCall.uploadApiServer<PrFrStat>(`/customer/v2/financialStmt/${customerId}/${srcType}/load`, form);
    }

    saveMainIssue = async (customerId: string, data: ModifyCustomerIssueForm) => {
        return await this.apiCall.json<ApiResult<string>>(`/customer/input/sole/mainIssue/${customerId}`, data);
    }

    getMnaBrokerage = async (customerId: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerMnaBrokerageDto>>(`/customer/input/mna/brokerage/${customerId}`);
    }

    updateMnaBrokerage = async (customerId: string, form: CustomerMnaBrokerageForm) => {
        return await this.apiCall.json<ApiResult<string>>(`/customer/input/mna/brokerage/${customerId}`, form);
    }

    pagingMnaBrokerage = async (pageNo: number, size: number, searchData: {[key: string]: string|undefined}) => {
        return await this.apiCall.fetch<Page<CustomerMnaBrokerageDto>>(`/customer/input/mna/brokerage/paging?page=${pageNo}&size=${size}${makeSearchDataQs(searchData)}`);
    }
}